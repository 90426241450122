import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("ServerGroupUsers/");

const GetAllGroupUsers = (groupId, SetUsers) => 
{
    defaultAxios.get("Get/" + groupId)
    .then((res) => 
    {
        SetUsers(res.data);
    });
}

const AddUserToGroup = (groupId, userId, Permissions, onSuccess) => 
{
    const data = {
        ServerGroupId: groupId,
        UserId : userId,
        Permissions: Permissions
    };

    defaultAxios.post("Add", data)
    .then((res) => 
    {
        onSuccess();
    });
}

const EditUserInGroup = (UserToServerGroupId, Permissions, groupId, SetUsers) => 
{
    const data = {
        Permissions: Permissions,
        UserToServerGroupId : UserToServerGroupId,
    };

    defaultAxios.post("Edit", data)
    .then((res) => 
    {
        GetAllGroupUsers(groupId, SetUsers);
    });
}

const RemoveUserFromGroup = (userToGroupId, groupId, SetUsers) => 
{
    defaultAxios.delete("Remove/"+userToGroupId)
    .then((res) => 
    {
        GetAllGroupUsers(groupId, SetUsers);
    });
}

export { GetAllGroupUsers, AddUserToGroup, EditUserInGroup, RemoveUserFromGroup };