import { useParams } from "react-router-dom";
import GroupButtons from "../Elements/GroupButtons/GroupButtons";
import { useEffect, useState } from "react";
import "./ServerManagement.css";
import { GetPunishmentsData } from "../../Scripts/Requests/ServerRequests";
import SCPMute from "./Elements/SCP/SCPMute";
import SCPBan from "./Elements/SCP/SCPBan";

const ServerManagement = () => {
    const { id } = useParams();
    const [selectedCategory, setSelectedCategory] = useState(0);

    const [reload, setReload] = useState("");
    const [data, SetData] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        GetPunishmentsData(id, SetData, setReload);
    }, []);

    useEffect(() => {
        GetPunishmentsData(id, SetData, setReload);
    }, [reload])

    if(data.length < 3)
        return (<></>);

    return (
        <div className="serverManagement">
            <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "Mutes"}, {text: "Bans"}, {text: "IP Bans"}]} />
            <input onChange={(e) => setSearch(e.target.value)} className="inputStyle" type="text"/>

            { selectedCategory == 0 &&
                <div className="widgetListContainer" style={{height:"66vh"}}>
                    {data[2].filter(x => x.toLowerCase().includes(search.toLowerCase())).map((punishment, index) => <SCPMute key={`SCP_Mute_${index}`} setReload={setReload} setPunishments={SetData} serverId={id} mute={punishment}/>)}
                </div>
            }
            { selectedCategory == 1 &&
                <div className="widgetListContainer" style={{height:"66vh"}}>
                    {data[0].filter(x => x.toLowerCase().includes(search.toLowerCase())).map((punishment, index) => <SCPBan key={`SCP_Ban_${index}`} setReload={setReload} setPunishments={SetData} serverId={id} ip={false} ban={punishment}/>)}
                </div>
            } 
            { selectedCategory == 2 &&
                <div className="widgetListContainer" style={{height:"66vh"}}>
                    {data[1].filter(x => x.toLowerCase().includes(search.toLowerCase())).map((punishment, index) => <SCPBan key={`SCP_IP_Ban_${index}`} setReload={setReload} setPunishments={SetData} serverId={id} ip={true} ban={punishment} />)}
                </div>
            }
        </div>
    )
}

export default ServerManagement;