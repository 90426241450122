import { AddInfoBoxMessage } from "../InfoBoxManager";
import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("Servers/");

const GetServer = (id, SetServer) => 
{
    defaultAxios.get("Get/" + id)
    .then((res) => 
    {
        SetServer(res.data);
    });
}

const GetServers = (SetServers, navigate) => 
{
    defaultAxios.get("GetAllServers")
    .then((res) => 
    {
        SetServers(res.data);
    })
}

const AddServer = (serverType, SetServers, navigate, SetServerKey) => 
{
    const data = {
        ServerType : serverType
    }

    defaultAxios.post("AddServerToPanel", data)
    .then((res) => 
    {
        SetServerKey(res.data);
        GetServers(SetServers, navigate);
    })
};

const RemoveServer = (id, SetServers, navigate) => 
{
    defaultAxios.delete("RemoveServerFromPanel/" + id)
    .then((res) => 
    {
        GetServers(SetServers, navigate);
    });
};

const ExecuteCommand = (serverId, command, successMessage) => 
{
    defaultAxios.post("ExecuteCommand/" + serverId, command, {headers: {"Content-Type": "application/json" }})
    .then((res) => 
    { 
        if(successMessage)
            AddInfoBoxMessage(successMessage, 1);
        else
            AddInfoBoxMessage("The command was successfully sent for execution!", 1);

    });
}

const GetStaffChat = (serverId, SetStaffChat, navigate) => 
{
    defaultAxios.get("GetStaffChat/"+serverId)
    .then((res) => 
    {
        SetStaffChat(res.data);
    });
}

const GetLogs = (serverId, logTypes, search, SetServerLogs, messagesEndRef) => 
{
    const data = {
        serverId: serverId,
        logTypes: logTypes,
        search: search,
    }
    defaultAxios.post("SearchServerLogs", data)
    .then((res) => 
    {
        SetServerLogs(res.data);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    });
}

const GetPunishmentsData = (serverId, SetPunishments, setReload) => 
{
    defaultAxios.get("GetPunishmentsData/"+serverId)
    .then((res) => 
    {
        SetPunishments(res.data.data);
        setReload(JSON.stringify(new Date().toString()));
    });
}

const EditPunishment = (serverId, type, oldData, editedData, SetPunishments, setReload) => 
{
    const data = {
        ServerId : serverId,
        Type : type,
        OldData: oldData,
        EditedData: editedData
    }

    defaultAxios.post("EditPunishment/", data)
    .then((res) => 
    {
        setTimeout(() => {
            AddInfoBoxMessage("Punishment Edited", 1);
            GetPunishmentsData(serverId,  SetPunishments, setReload);
        }, 100)

    });
}

const RemovePunishment = (serverId, type, dataToRemove, SetPunishments, setReload) => 
{
    const data = {
        ServerId : serverId,
        Type : type,
        Data: dataToRemove
    }

    defaultAxios.post("RemovePunishment/", data)
    .then((res) => 
    {
        AddInfoBoxMessage("Punishment removed", 1);
        GetPunishmentsData(serverId,  SetPunishments, setReload);
    });
}


export { GetServers, AddServer, RemoveServer, ExecuteCommand, GetStaffChat, GetLogs, GetServer, GetPunishmentsData, RemovePunishment, EditPunishment };