import "./Account.css";
// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import GroupButtons from "../Elements/GroupButtons/GroupButtons.js";
import AccountData from "./AccountCategories/AccountData.js";
import AccountSecurity from "./AccountCategories/AccountSecurity/AccountSecurity.js";

const Account = () => {

    const [selectedCategory, setSelectedCategory] = useState(0);

    // const [editWindow, setEditWindow] = useState(false);
    
    return (
        <>
            <div style={{padding:"20px 15%"}}>
                <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "Your Account"}, {text: "Security"}]}/>
            </div>

            <div className="profileInfoContainer">
                { selectedCategory == 0 &&
                    <AccountData />
                }
                { selectedCategory == 1 &&
                    <AccountSecurity />
                }
            </div>
        </>
    )
}

export default Account;