import "./UserSettings.css";
import UserPanel from "./Elements/UserPanel.js";
import { GetUsers, AddUser } from "../../../Scripts/Requests/UsersRequests.js";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow.js";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";
import { FaPlus } from "react-icons/fa";

const UserSettings = () => {
    const [users, setUsers] = useState([]);

    const [addUserWindow, setAddUserWindow] = useState(false);

    const [userLogin, setUserLogin] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userDiscordId, setUserDiscordId] = useState("");
    const [userSteamID, setUserSteamID] = useState("");

    const navigate = useNavigate();
    const cookies = new Cookies();

    const permissions = jwtDecode(cookies.get("jwt")).Permissions;

    useEffect(() => 
    {
        GetUsers(setUsers);
    }, []);

    return(
        <>
            <div className="widgetListContainer" style={{maxHeight: "80%"}}>
                {users.map((user => 
                    <UserPanel key={`userPanel_${user.panelUser.id}`} user={user} permissions={permissions} navigate={navigate} setUsers={setUsers} />
                ))}
                <button onClick={() => setAddUserWindow(true) } className="widgetListMainButton">Add User <FaPlus style={{marginLeft: "0.25vw"}} /></button>
            </div>

            { addUserWindow != "" &&
                <ModalWindow showCloseButton={true} closeFunction={() => setAddUserWindow(false)} title={"Add user"} width={"30%"} content=
                {
                    <>
                        <p>Email</p>
                        <input onChange={(e) => setUserEmail(e.currentTarget.value)} style={{width: "98%"}} type="text"/>

                        <p>Login</p>
                        <input onChange={(e) => setUserLogin(e.currentTarget.value)} style={{width: "98%"}} type="text"/>

                        <p>SteamID64</p>
                        <input onChange={(e) => setUserSteamID(e.currentTarget.value)} style={{width: "98%"}} type="text"/>

                        <p>DiscordID</p>
                        <input onChange={(e) => setUserDiscordId(e.currentTarget.value)} style={{width: "98%"}} type="text"/>

                        <button 
                        style={{width: "98%"}} 
                        onClick={() => { AddUser({ Email: userEmail, Login: userLogin, Permissions: 0, DiscordId : userDiscordId, SteamId64: userSteamID }, setUsers, navigate); setAddUserWindow(false) }}>
                        Add</button>
                    </>
                }/>
            }
        </>
    )
}

export default UserSettings;