import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("ServerGroupServers/");


const GetAllGroupServers = (groupId, SetServers) => 
{
    defaultAxios.get("Get/" + groupId)
    .then((res) => 
    {
        SetServers(res.data);
    });
}

const AddServerToGroup = (groupId, serverId, onSuccess) => 
{
    const data = {
        GroupId: groupId,
        ServerId : serverId,
    };

    defaultAxios.post("Add", data)
    .then((res) => 
    {
        onSuccess();
    });
}

const RemoveServerFromGroup = (serverToGroupId, groupId, SetServers) => 
{
    defaultAxios.delete("Remove/"+serverToGroupId)
    .then((res) => 
    {
        GetAllGroupServers(groupId, SetServers);
    });
}

export {GetAllGroupServers, AddServerToGroup, RemoveServerFromGroup};