import { FaExchangeAlt } from "react-icons/fa";
import playerRoles from "../../../../../Data/PlayerRoles.js";

const PlayerChangedRoleLog = ({content}) => {
    const splitted = content.split(";");

    const oldRole = playerRoles.find(x => x.id == splitted[3]);
    const newRole = playerRoles.find(x => x.id == splitted[4]);

    return (
        <>
            <div>
                <div style={{ display: "flex" }}> 
                    <FaExchangeAlt size={"25px"} color="lightgrey"/>
                    <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Player's role changed</h3>
                </div>
                <div style={{ marginLeft:"10px" }}>
                    <div>Player: <span style={{color:"#b0b0b0"}}>[{splitted[0]}]</span> {splitted[2]} <span style={{color:"#b0b0b0"}}>({splitted[1]})</span></div>
                    {/* <div>Player: {splitted[2]}</div>
                    <div>Player Id: {splitted[0]}</div>
                    <div>User Id: {splitted[1]} </div> */}
                    { oldRole &&
                        <div>Previous role: <span style={{color: oldRole.color}}>{oldRole.name}</span></div>
                    }
                    { newRole &&
                        <div>Current role: <span style={{color: newRole.color}}>{newRole.name}</span></div>
                    }
                    
                </div>
            </div>
        </>
    )
}

export default PlayerChangedRoleLog;