import { useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight, FaCopy } from "react-icons/fa";
import { AddInfoBoxMessage } from "../../../../../Scripts/InfoBoxManager";
import { IoEye } from "react-icons/io5";

const PlayerJoinLeftLog = ({content, joined}) => {
    const splitted = content.split(";");
    const [ipHidden, setIpHidden] = useState(true);

    return (
        <>
            { joined &&
                <div style={{display: "flex"}}>
                    <FaArrowCircleRight size={"25px"} color="lime"/>
                    <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Player Joined</h3>
                </div>
            }
            { !joined &&
                <div style={{display: "flex"}}> 
                    <FaArrowCircleLeft size={"25px"} color="#ff481a"/>
                    <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Player Left</h3>
                </div>
            }
            
            <div style={{marginLeft:"10px" }}>
                <div>Nick: {splitted[2]}</div>
                <div>Player Id: {splitted[0]}</div>
                <div style={{display: "flex"}}>
                    <div>User Id: {splitted[1]}</div>
                    <FaCopy onClick={() => {navigator.clipboard.writeText(splitted[1]); AddInfoBoxMessage("Id copied to clipboard", 1)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"1.7vh"}/>
                </div>
                
                <div style={{display: "flex"}}>
                    { ipHidden &&
                        <div>IP: *****************</div>
                    }
                    { !ipHidden &&
                        <div>IP: {splitted[3]}</div>                           
                    }  
                    <IoEye onClick={() => { setIpHidden(!ipHidden) }} className="iconButton" style={{marginLeft: "0.5vw"}} size={"2vh"}/>
                    <FaCopy onClick={() => {navigator.clipboard.writeText(splitted[3]); AddInfoBoxMessage("IP copied to clipboard", 1)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"1.7vh"}/>
                </div>
              
            </div>

        </>
    )
}

export default PlayerJoinLeftLog;