import { FaClock, FaCommentDots } from "react-icons/fa";
import { ImHammer2 } from "react-icons/im";
import "./PredefinedReason.css"
import ModalWindow from "../../../../Elements/ModalWindow/ModalWindow";
import { useState } from "react";
import { AddInfoBoxMessage } from "../../../../../Scripts/InfoBoxManager";

const PredefinedReason = ({reason, time, ExecuteBanUser, GetPlayersGameIds}) => {
    const [modalActive, setModalActive] = useState(false);

    const OpenModal = () =>
    {
        if(GetPlayersGameIds().length > 1)
        {
            setModalActive(true);
        }else 
        {
            AddInfoBoxMessage("You need to select user(s) for this action", 0);
        }
    }
 
    return (
        <>
            <div className="predefinedReason">
                <button onClick={() => OpenModal()} className="banBtn"><ImHammer2 style={{marginRight:"0.5vw"}}/>BAN [<FaClock style={{marginRight:"0.25vw"}}/>{time}]</button>
                <div className="reasonDesc"><FaCommentDots style={{marginRight:"0.5vw"}}/>{reason}</div>
            </div>
            { modalActive &&
                <ModalWindow
                    width={"650px"}
                    title={`Banowanie gracza`}
                    content={
                        <>
                            <p>Czy na pewno chcesz zbanować użytkownika/użytkowników za '<strong>{reason}</strong>'?</p>
                            <div style={{display:"flex"}}>
                                <button onClick={() => {ExecuteBanUser(time, reason); setModalActive(false)}} style={{width:"50%"}}><ImHammer2 style={{marginRight:"0.5vw"}}/> Banuj</button>
                                <button onClick={() => {setModalActive(false)}} style={{width:"50%"}}>Anuluj</button>
                            </div>
                        </>
                    }
                />
            }

        </>
    );
}

export default PredefinedReason;