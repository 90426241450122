import { Permissions } from "../Data/Permissions";

const CheckPermission = (groupPermissions, userPermissions, permissionRequired, canAdmin) => 
{
    const adminCheck = ((userPermissions & Permissions.Admin) && canAdmin);
    const rootCheck = (userPermissions & Permissions.Root);
    const permissionCheck = (groupPermissions & permissionRequired) != 0;
    
    return (permissionCheck || adminCheck || rootCheck) == true;
}

const GetPermissionsToDisplay = ({permissions, permissionsNames, permissionsEnum}) => {
    return (
        <>
            { permissions == 0 && <div>None</div> }
            {
                permissionsNames.map(permissionName => 
                    {
                        if(permissions & permissionsEnum[permissionName.Enum]) { return <div key={`permission_${permissionName.Enum}`}>{ permissionName.Name }</div> }
                    }
                )
            }
        </>
    )
}

export { CheckPermission, GetPermissionsToDisplay };