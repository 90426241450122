const GetDateFromTicks = (ticks) => {
    return new Date((ticks - 621355968000000000) / 10000);
}
const GetTicksFromDate = (date) => {
    return ((date.getTime() * 10000) + 621355968000000000);
}

const GetTimeDifference = (olderDate, newerDate) => {
    let timeDifference = newerDate - olderDate;
    
    const dd = Math.floor(timeDifference / 1000 / 3600 / 24);
    timeDifference -= dd * 1000 * 3600 * 24;
    const hh = Math.floor(timeDifference / 1000 / 3600)
    timeDifference -= hh * 1000 * 3600;
    const mm = Math.floor(timeDifference / 1000 / 60);
    timeDifference -= mm * 1000 * 60;
    const ss = Math.floor(timeDifference / 1000);

    let dateString = `${ss} seconds`;

    if(mm) {
        dateString = `${mm} minutes ${ss} seconds`
    }

    if(hh) {
        dateString = `${hh} hours ${mm} minutes`
    }

    if(dd < 7 && dd) {
        dateString = `${dd} days ${hh}`
    }

    if(dd > 7) {
        dateString = `${dd} days`
    }

    return dateString;
};

export { GetDateFromTicks, GetTicksFromDate, GetTimeDifference }