import { useEffect, useState } from "react";
import { GetServers, AddServer, RemoveServer } from "../../Scripts/Requests/ServerRequests.js";
import ServerPanel from "./Elements/ServerPanel.js";
import { FaCopy, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../Elements/ModalWindow/ModalWindow.js";
import { CheckPermission } from "../../Scripts/PermissionsManager.js";
import { Permissions } from "../../Data/Permissions.js";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";
import { IoEye } from "react-icons/io5";

const ServerList = () => {

    const [keyHidden, setKeyHidden] = useState(true);

    const [addServerWindow, setAddServerWindow] = useState(false)
    const [serverType, setServerType] = useState(0)
    const [serverData, setServerData] = useState([]);
    const [serverKey, setServerKey] = useState('');
    const navigate = useNavigate();

    const cookies = new Cookies();

    useEffect(() => 
    {
        GetServers(setServerData, navigate);
    }, []);

    let UserPermissions = 0;

    if(cookies.get("jwt") == null) {
        navigate("/");
    }
    else {
        UserPermissions = jwtDecode(cookies.get("jwt")).Permissions;
    }

    const RemoveServerFromList = (serverId) => {
        RemoveServer(serverId, setServerData, navigate)
    }

    return (
    <div className="widgetListContainer">
        {
            serverData.map((server) => 
                <ServerPanel key={`server_${server.id}`} server={server} removeServer={RemoveServerFromList}/>
            )
        }

        { CheckPermission(UserPermissions, Permissions.Admin, true) && 
            <button onClick={() => { setServerType(0); setAddServerWindow(true); }} className="widgetListMainButton">Add Server <FaPlus style={{marginLeft: "0.25vw"}}/></button>
        }
        

        { addServerWindow &&
            <ModalWindow showCloseButton={ true } closeFunction={ () => {setAddServerWindow(false)} } title={"Add new server"} width={"30%"} content=
            {
                <>
                    <select className="inputStyle" onChange={(e) => setServerType(e.target.options.selectedIndex) } >
                        <option>SCP: Secret Laboratory</option>
                        <option>Unturned</option>
                    </select>                
                    <button onClick={() => { AddServer(serverType, setServerData, navigate, setServerKey); setAddServerWindow(false); setKeyHidden(!false)}} style={{width:"98%"}} >Add Server <FaPlus style={{marginLeft: "0.25vw"}}/></button>
                </>
            }/>
        }
        { serverKey != "" &&
            <ModalWindow showCloseButton={ true } closeFunction={ () => {setServerKey("")} } title={"Server Added"} width={"30%"} content=
                {
                    <>
                        <div style={{ width:"80%", margin:"auto"}}>
                            <p style={{marginBottom:"0px", textAlign:"center"}}>Server Access Key:</p>
                            
                            <div style={{display: "flex", alignItems: "center"}}>
                                { keyHidden &&
                                    <>****************************************************</>
                                }
                                { !keyHidden &&
                                    <>{ serverKey }</>
                                }
                                
                                <IoEye onClick={() => { setKeyHidden(!keyHidden) }} className="iconButton" style={{marginLeft: "0.75vw"}} size={"2.5vh"}/>
                                <FaCopy onClick={() => {navigator.clipboard.writeText(serverKey)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"2.5vh"}/>
                            </div>
                        </div>

                        
                        <h3 style={{textAlign:"center"}}>Klucz należy umieścić w configu serwera!</h3>
                        <h2 style={{color: "red", textAlign:"center"}}>Nie udostępniaj klucza nikomu!</h2>       
                    </>
                }/>
        }

    </div>)
}
export default ServerList;