import { RemovePunishment } from "../../../../Scripts/Requests/ServerRequests";

const SCPMute = ({serverId, mute, setPunishments, setReload}) => {
    if(!mute)
        return (<></>);

    let muteText = mute;

    if(mute.includes('-'))
        muteText = muteText.split('-')[1] + ' - Intercom';

    const Unmute = () => {
        RemovePunishment(serverId, 2, mute, setPunishments, setReload)
    }

    return (
        <div className="listWidget" style={{padding:"7px", marginBottom:"10px"}}>
            <div style={{display:"flex"}}>
                <p style={{width:"90%", marginLeft:"10px"}}>
                    {muteText}
                </p>
                <button onClick={() => Unmute()} style={{width:"10%"}}>Unmute</button>
            </div>
        </div>
    );
};

export default SCPMute;