import "./Inventory.css"
import {ItemCategories, Items} from "../../../../Data/Items.js";
import { useEffect, useState } from "react";
import ItemButton from "./Elements/ItemButton.js";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests.js";
import { useNavigate, useParams } from "react-router-dom";

const Inventory = ({GetPlayersGameIds}) => {
    const { id } = useParams();
    const navigate = useNavigate;

    let itemsInCategories = [];
    const [ItemChange, setItemChange] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {}, [ItemChange]);

    //Add categories to array
    for(let i = 0; i < ItemCategories.length; i++)
    {
        if(!itemsInCategories[i])
            itemsInCategories.push({category: ItemCategories[i], Items: []});
    }

    //Add items to array
    for(let i = 0; i < Items.length; i++)
    {
        if(Items[i].show)
            itemsInCategories[Items[i].categoryId].Items.push(Items[i]);
    }

    const ChangeItemSelection = (item) =>{
        let items = selectedItems;

        const selectedItem = items.find((x) => x.id == item.id);

        if(selectedItem != null)
            items.splice(items.indexOf(item), 1);
        else
            items.push(item);

        setItemChange(item.id + " " + selectedItem)
        setSelectedItems(items);
    }

    const GrantItems = () => {
        let command = "/give ";

        command += GetPlayersGameIds() + " ";

        selectedItems.forEach(item => {
            command += item.id + ".";
        });

        ExecuteCommand(id, command);
    }

    const RemoveItems = () => {
        let command = "/removeitem ";

        command += GetPlayersGameIds() + " ";
        
        selectedItems.forEach(item => {
            command += item.id + ".";
        });

        ExecuteCommand(id, command);
    }

    const ClearInventory = () => {
        let command = "/clear ";
        command += GetPlayersGameIds();

        ExecuteCommand(id, command);
    }

    const DisarmPlayer = () => {
        let command = "/disarm ";

        command += GetPlayersGameIds();

        ExecuteCommand(id, command);
    }

    const ReleaseHandcuffs = () => {
        let command = "/free ";

        command += GetPlayersGameIds();

        ExecuteCommand(id, command);
    }

    const ForceEquip = () => {
        let command = "/forceequip ";

        command += GetPlayersGameIds() + " ";
        
        selectedItems.forEach(item => {
            command += item.id + ".";
        });

        ExecuteCommand(id, command);
    }

    return(
        <div className="optionsPanelContainer">
            
            <div className="optionsPaneldescription">
                Select player(s), then select items you want to give to them and confirm your selection.
            </div>

            <div className="itemCategoriesContainer">
                { itemsInCategories.map((categoryAndItems) =>  
                    <fieldset key={"category_" + categoryAndItems.category.name} className="itemCategoryFieldset">
                        <legend key={"categoryLegend_" + categoryAndItems.category.name}>{categoryAndItems.category.name}</legend>
                        {
                            categoryAndItems.Items.map((item) => 
                            <>
                                <ItemButton key={`item_${item.id}`} item={item} isSelected={selectedItems.find(x => x.id == item.id)} ChangeItemSelection={ChangeItemSelection} />
                            </>
                            )
                        }
                    </fieldset>
                )}
            </div>

            <div className="itemActionButtonsContainer">
            <button onClick={() => GrantItems()} className="itemActionButton">GRANT ITEM(S)</button>
            <button onClick={() => RemoveItems()} className="itemActionButton">REMOVE ITEM(S)</button>
            <button onClick={() => ClearInventory()} className="itemActionButton">CLEAR INVENTORY</button>
            <button onClick={() => DisarmPlayer()} className="itemActionButton">DISARM PLAYER(S)</button>
            <button onClick={() => ReleaseHandcuffs()} className="itemActionButton">RELEASE HANDCUFFS</button>
            <button onClick={() => ForceEquip()} className="itemActionButton">FORCE-EQUIP</button>
            </div>
            

        </div>
    );
}
export default Inventory;