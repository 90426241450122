import "./GroupButtons.css"

const GroupButtons = ({changeValue, selected, buttons}) => 
{
    return (
        <div className="groupButtonsContainer">
            { buttons.map((button, index) => 
                <div key={`groupButton_${index}`} onClick={() => changeValue(index)} className={ "groupButton " + (selected  == index ?   "groupButtonSelected" : " ")}>
                    <div className="groupButtonText">
                        {button.text}
                    </div>
                    <div className="underline">

                    </div>
                </div>
            )}
        </div>
    )

}

export default GroupButtons;