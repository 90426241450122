import { FaSkull } from "react-icons/fa";
import "./ServerLogPanel.css";
import ServerLogType from "../../../Data/ServerLogType";
import PlayerJoinLeftLog from "./LogTypes/SCP/PlayerJoinLeftLog";
import PlayerKilledLog from "./LogTypes/SCP/PlayerKilledLog";
import PlayerChangedRoleLog from "./LogTypes/SCP/PlayerChangedRoleLog";
import ServerMainLog from "./LogTypes/SCP/ServerMainLog";
import { GetTimeDifference } from "../../../Scripts/DateManager";


const ServerLogPanel = ({log}) => {
    const date = new Date(Date.parse(log.logDate));
    const now = Date.now();

    const TimeAgo = GetTimeDifference(date, now);

    let content = log.content;

    switch(log.logType) {
        case 0:
            content = <ServerMainLog content={content} />
            break;
        case 1:
            content = <PlayerJoinLeftLog content={content} joined={true} />;
            break;
        case 2:
            content = <PlayerJoinLeftLog content={content} joined={false} />;
            break;
        case 6:
            content = <PlayerChangedRoleLog content={content} />
            break;
        case 7:
            content = <PlayerKilledLog content={content} />;
            break;    
    }

    return (
        <div className="listWidget" style={{marginBottom:"5px"}}>
            <div style={{margin: "10px 0px 10px 10px"}}>
                {content}
            </div>
            
            <div className="ServerPanelLogFooter">
                {date.toLocaleTimeString("pl-PL")} {date.toLocaleDateString("pl-PL")} [{TimeAgo} ago]
            </div>
        </div>
    )
}
export default ServerLogPanel;