import { FaSkull } from "react-icons/fa";
import playerRoles from "../../../../../Data/PlayerRoles";

const PlayerKilledLog = ({content}) => {
    const splitted = content.split(";");

    const role = playerRoles.find(x => x.id == splitted[3]);

    const killerRole = playerRoles.find(x => x.id == splitted[9]);

    return (
        <>
            <div>
                <div style={{display: "flex" }}>
                    { (splitted[5] == "0" && splitted[7] != splitted[1]) &&
                        <>
                            <FaSkull size={"25px"} />
                            <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Kill</h3>
                        </>
                    }
                    { splitted[5] == "1" &&
                        <>
                            <FaSkull size={"25px"} color="red"/>
                            <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%", color:"red" }}>Teamkill</h3>
                        </>
                        
                    }
                    { splitted[5] == "2" &&
                        <>
                            <FaSkull size={"25px"} />
                            <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Cuffed Killed</h3>
                        </>
                    }
                    { splitted.length < 6 &&
                        <>
                            <FaSkull size={"25px"} />
                            <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Death</h3>
                        </>
                    }

                    { (splitted.length > 6 && splitted[7] == splitted[1]) &&
                        <>
                            <FaSkull size={"25px"} />
                            <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>Suicide</h3>
                        </>
                    }
                </div>

                    
                <div style={{marginLeft:"10px", display: "flex", marginTop:"5px" }}>
                    <div>
                        <h3 style={{marginBottom:"0px", marginTop:"0px" }}>Victim:</h3>
                        <div>Player: <span style={{color:"#b0b0b0"}}>[{splitted[0]}]</span> {splitted[2]} <span style={{color:"#b0b0b0"}}>({splitted[1]})</span></div>
                        {/* <div>Nick: {splitted[2]}</div>
                        <div>Player Id: {splitted[0]}</div>
                        <div>User Id: {splitted[1]}</div> */}
                        { role &&
                            <div>Role: <span style={{color: role.color}}>{role.name}</span></div>
                        }
                        
                    </div>

                    { (splitted.length >= 6 && splitted[7] != splitted[1]) &&
                        <div style={{marginLeft:"40px" }}>
                            <h3 style={{marginBottom:"0px", marginTop:"0px" }}>Killer:</h3>
                            <div>Player: <span style={{color:"#b0b0b0"}}>[{splitted[6]}]</span> {splitted[8]} <span style={{color:"#b0b0b0"}}>({splitted[7]})</span></div>
                            {/* <div>Nick: {splitted[8]}</div>
                            <div>User Id: {splitted[6]}</div>
                            <div>Player Id: {splitted[7]} </div> */}
                            { killerRole &&
                                <div>Role: <span style={{color: killerRole.color}}>{killerRole.name}</span></div>
                            }
                        </div> 
                    }
                              
                </div>
                <h3 style={{marginLeft:"10px", display: "flex", marginTop:"5px", marginBottom:"5px" }}>Reason: {splitted[4]}</h3>    
            </div>
        </>
    )
}

export default PlayerKilledLog;