const ServerLogType = [
    { id : 0, name: "ServerLog" },
    { id : 1, name: "PlayerJoined" },
    { id : 2, name: "PlayerLeft" },
    { id : 3, name: "Ban" },
    { id : 4, name: "RACommandUsed" },
    { id : 5, name: "UserReports" },
    { id : 6, name: "PlayerRoleChanged" },
    { id : 7, name: "PlayerKilled" },
    { id : 8, name: "PlayerDamaged" },
    { id : 9, name: "CommandUsed" },
    { id : 10, name: "Mute" },
    { id : 11, name: "Unmute" },
    { id : 12, name: "Kick" },
];

export default ServerLogType;