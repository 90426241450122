import { useEffect, useRef, useState } from "react";
import { GetLogs } from "../../Scripts/Requests/ServerRequests";
import { useNavigate, useParams } from "react-router-dom";
import ServerLogPanel from "./ServerLogPanel/ServerLogPanel";
import ServerLogType from "../../Data/ServerLogType";

const ServerLogs = () => {
    const getDefaultLogTypeSearch = () => {
        let dataToReturn = [];

        for (let log of ServerLogType) {
            dataToReturn.push(log.id)
        }
        
        return dataToReturn;
    }

    const editLogTypesSearch = (id, value) => {
        if(value) 
            logTypesSearch.push(id); 
        else
            logTypesSearch.splice(logTypesSearch.indexOf(id), 1);

        setRefresh(`${id}_${value}`);
    }

    const [serverLogs, SetServerLogs] = useState(null);
    const [refresh, setRefresh] = useState("");

    const [search, setSearch] = useState("");
    const [logTypesSearch, setLogTypesSearch] = useState(getDefaultLogTypeSearch());

    const { id } = useParams();

    const messagesEndRef = useRef(null);

    useEffect(() => {
        Search();
    }, [])

    const Search = () => {
        GetLogs(id, logTypesSearch, search, SetServerLogs, messagesEndRef);
    }

    if(serverLogs === null) {
        return (<>
        <h1 style={{marginTop:"200px", textAlign:"center"}}>Loading...</h1>
        </>);
    }

    return (
        <div className="panelField" style={{height: "calc(100% - (20px + 3vh))"}}>
            <div className="logsSidePanel">
                <div className="logsSidePanelContainer">
                    <input onChange={(e) => setSearch(e.target.value)}  style={{marginBottom: "20px"}} type="text" className="inputStyle" placeholder="Search..."/>
                    <div style={{display:"block", marginBottom: "20px"}}>
                        { ServerLogType.map(logType => 
                            <div key={`logType_${logType.id}`}>
                                <input checked={logTypesSearch.includes(logType.id)} onChange={(e) => editLogTypesSearch(logType.id, e.target.checked)} type="checkbox"/>
                                {logType.name}
                            </div>
                        )}
                    </div>
                    <div>
                        <button onClick={() => Search()}>Search</button>
                        <button onClick={() => { setLogTypesSearch([]); setLogTypesSearch(getDefaultLogTypeSearch());}} style={{marginLeft: "2%"}}>Clear filter</button>
                    </div>
                </div>
            </div>
            <div className="widgetListContainer serverLogsField">
            
                { serverLogs === null &&
                    <h1 style={{marginTop:"200px", textAlign:"center"}}>Loading...</h1>
                }
                { serverLogs !== null &&
                    <>
                        {serverLogs.map((log) => 
                            <ServerLogPanel key={`ServerLogPanel_${log.id}`} log={log}/>
                        )}
                        <div ref={messagesEndRef}/>                    
                    </>
                }

            </div>
        </div>
    )
}

export default ServerLogs;