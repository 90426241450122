import axios from "axios";
import { Cookies } from "react-cookie";
import { AddInfoBoxMessage } from "../InfoBoxManager";

const getDefaultAxios = (url) => {
    const cookies = new Cookies();

    const defaultAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL + 'api/AdminPanel/'+ url,
        headers: { Authorization: "Bearer " + cookies.get("jwt"), "Content-Type": "application/json" },
    });
    
    defaultAxios.interceptors.request.use((config) => 
    {
        config.headers.Authorization = "Bearer " + cookies.get("jwt");
        return config;
    })


    defaultAxios.interceptors.response.use((response) => response, (error) => {
        AddInfoBoxMessage(error.message + ` [${error.config.url}]`, 0);
        console.error(error);

        return Promise.reject(error);
    });

    return defaultAxios;
}



export  { getDefaultAxios };