import { FaServer } from "react-icons/fa";

const ServerMainLog = ({content}) =>{
    return (
        <div style={{display: "flex"}}>
            <FaServer size={"25px"} color="rgb(61, 201, 220)"/>
            <h3 style={{marginLeft:"10px", marginBottom:"5px", marginTop:"0px", fontSize: "125%"}}>{content}</h3>
        </div>
    )
}

export default ServerMainLog;