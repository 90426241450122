import { useState } from "react";
import { RemoveUser, ResetPassword } from "../../../../Scripts/Requests/UsersRequests.js";
import {PermissionsNames, Permissions} from "../../../../Data/Permissions.js";
import { EditUser } from "../../../../Scripts/Requests/UsersRequests.js";
import ModalWindow from "../../../Elements/ModalWindow/ModalWindow.js";
import { FaCopy, FaKey, FaPen, FaTrash } from "react-icons/fa";
import { GetPermissionsToDisplay } from "../../../../Scripts/PermissionsManager.js";

const UserPanel = ({user, navigate, setUsers, permissions}) => {
    const [removeUserWindow, setRemoveUserWindow] = useState(false); 
    const [editUserWindow, setEditUserWindow] = useState(false);
    const [resetPasswordWindow, setResetPasswordWindow] = useState(false);

    const [userEmail, setUserEmail] = useState(user.panelUser.email); 
    const [userSteamID, setUserSteamID] = useState(user.panelUser.steamId64);
    const [userDiscordId, setUserDiscordId] = useState(user.panelUser.discordId);

    let DefaultArray = []

    PermissionsNames.map(permissionName => 
        {
            if(user.panelUser.permissions & Permissions[permissionName.Enum]) 
                DefaultArray.push(Permissions[permissionName.Enum])
        }
    )

    const [userPermissionsArray, setUserPermissionsArray] = useState(DefaultArray);

    function OnPermissionCheckedChange(isChecked, enumNumber) {
        let arrayToEdit = userPermissionsArray;
        
        if(isChecked)
            arrayToEdit.push(Permissions[enumNumber]);
        else
            arrayToEdit.splice(arrayToEdit.indexOf(Permissions[enumNumber]), 1);

        setUserPermissionsArray(arrayToEdit);

        let permissionNumber = 0;
        userPermissionsArray.forEach((permission) => permissionNumber += permission);

        setUserPermissions(permissionNumber)
    }

    const [userPermissions, setUserPermissions] = useState(user.panelUser.permissions);

    return(
        <div className="listWidget">
            <div style={{display: "flex"}}>
                <div className="profileDataTableContainer tableStyles">
                    <table style={{fontSize: "1.125rem", margin: "0.5em 0.5em 0 0.5em", width: "50%"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="steamProfileImageContainer">
                                        <img alt="steam profile icon" className="steamProfileImage" src={user.steamPlayer.avatarfull} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="infoCell">Login:</td>
                                <td className="dataCell">{user.panelUser.login}</td>
                            </tr>
                            <tr>
                                <td className="infoCell">Email:</td>
                                <td className="dataCell">{user.panelUser.email}</td>
                            </tr>
                            <tr>
                                <td className="infoCell" style={{width: "30%"}}>Steam ID:</td>
                                <td className="dataCell">
                                    <div style={{display: "flex"}}>
                                        {user.panelUser.steamId64}
                                        <FaCopy onClick={() => {navigator.clipboard.writeText(user.panelUser.steamId64)}} className="iconButton" style={{marginLeft: "0.7vw"}} size={"1.8vh"}/>
                                   </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="infoCell">Discord ID:</td>
                                <td className="dataCell">
                                    <div style={{display: "flex"}}>
                                        {user.panelUser.discordId}
                                        <FaCopy onClick={() => {navigator.clipboard.writeText(user.panelUser.discordId)}} className="iconButton" style={{marginLeft: "0.7vw"}} size={"1.8vh"}/>
                                    </div>
                                </td>
                            </tr>                                         
                        </tbody>
                    </table>
                </div>

                <div style={{marginTop: "20px", width: "50%", marginRight:"40px"}}>
                    <h3 style={{textAlign:"center"}}>Permissions</h3>
                    <div className="accountListContainer" style={{ width:"100%", height: "auto", minHeight: "150px"}}>
                        <GetPermissionsToDisplay permissions={user.panelUser.permissions} permissionsNames={PermissionsNames} permissionsEnum={Permissions}/>
                    </div>
                </div>
            </div>
            
            { (user.permissions != 1 && (permissions != user.panelUser.permissions)) &&
                <div className="userButtons">
                    <button onClick={() => { setEditUserWindow(true) }}> <FaPen style={{marginRight: "0.4vw"}} /> Edit User</button>
                    <button onClick={() => { setResetPasswordWindow(true) }}><FaKey style={{marginRight: "0.4vw"}}/>Reset Password</button>
                    <button onClick={() => { setRemoveUserWindow(true) }}><FaTrash style={{marginRight: "0.4vw"}}/> Remove User</button>
                </div>
            }


            { removeUserWindow &&
                <ModalWindow title={"Remove user?"} width={"30%"} content=
                {
                    <>
                        <p>This action can't be undone</p>
                        <div style={{display:"flex"}}>
                            <button style={{width:"50%"}} onClick={() => { RemoveUser(user.panelUser.id, setUsers); setRemoveUserWindow(false) }}>Remove</button>
                            <button style={{width:"50%"}} onClick={() => { setRemoveUserWindow(false) }}>Cancel</button>
                        </div>
                    </>
                }/>
            }
            { resetPasswordWindow &&
                <ModalWindow title={"Reset password?"} width={"30%"} content=
                {
                    <>
                        <p>Do you want to reset the password for user {user.panelUser.Username}?</p>
                        <div style={{display:"flex"}}>
                            <button style={{width:"50%"}} onClick={() => { ResetPassword(user.panelUser.id); setResetPasswordWindow(false) }}>Reset</button>
                            <button style={{width:"50%"}} onClick={() => { setResetPasswordWindow(false) }}>Cancel</button>
                        </div>
                    </>
                }/>
            }
            { editUserWindow &&
                <ModalWindow showCloseButton={true} closeFunction={() => setEditUserWindow(false)} title={"Edit user"} width={"60%"} content=
                {
                    <>
                        <div style={{width: "95.85%"}}>
                            <p>Email</p>
                            <input onChange={(e) => setUserEmail(e.currentTarget.value)} defaultValue={user.panelUser.email} style={{width: "98%"}} type="text"/>
                        </div>

                        <div style={{display: "flex"}}>
                            <div style={{width: "45%", marginRight: "5%"}}>
                                <p>Steam ID</p>
                                <input onChange={(e) => setUserSteamID(e.currentTarget.value)} defaultValue={user.panelUser.steamId64} style={{width: "98%"}} type="text"/>
                            </div>

                            <div style={{width: "45%"}}>
                                <p>Discord ID</p>
                                <input onChange={(e) => setUserDiscordId(e.currentTarget.value)} defaultValue={user.panelUser.discordId} style={{width: "98%"}} type="text"/>
                            </div>
                        </div>

    
                        <div style={{width: "100%", marginBottom: "20px"}}>
                            <p>Permissions</p>
                            {
                                PermissionsNames.map(permissionName => 
                                    { 
                                        if(permissionName.Enum != "Root")
                                            return(
                                                <div style={{display: "inline-block", width:"25%"}}>
                                                    <input defaultChecked={user.panelUser.permissions & Permissions[permissionName.Enum]} onChange={e => OnPermissionCheckedChange(e.target.checked, permissionName.Enum )} type="checkbox" />
                                                    <label>{permissionName.Name}</label>
                                                </div>
                                            )
                                    }                              
                                )
                            }
                        </div>

                        <button 
                        style={{width: "98%"}} 
                        onClick={() => { EditUser({ Id: user.panelUser.id, Email: userEmail, Login: user.panelUser.login, DiscordId : userDiscordId, SteamId64: userSteamID, Permissions: parseInt(userPermissions) }, setUsers); setEditUserWindow(false) }}>
                        Edit</button>
                    </>
                }/>
            }
        </div>
    )
}

export default UserPanel;