import './Styles/App.css';
import './Styles/PlayerList.css';
import './Styles/InputStyles.css';
import "./Styles/WidgetListStyles.css";

import "./Pages/Elements/ModalWindow/ModalWindow.css";
import { FaUserFriends, FaUser } from "react-icons/fa";
import { IoMdSettings, IoMdHome } from "react-icons/io";
import RemoteAdmin from './Pages/RemoteAdmin/RemoteAdmin';
import { useEffect, useState } from 'react';
import LoginPage from './Pages/Login/Login';
import ServerList from "./Pages/ServerList/ServerList";
import { Cookies } from 'react-cookie';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { RefreshToken } from './Scripts/Requests/AuthRequests';
import Settings from './Pages/Settings/Settings';
import AccountSettings from './Pages/Account/Account';
import { CheckPermission } from './Scripts/PermissionsManager';
import { Permissions } from './Data/Permissions';
import { jwtDecode } from 'jwt-decode';
import ServerGroup from './Pages/ServerGroup/ServerGroup';
import InfoBox from './Pages/Elements/InfoBox/InfoBox';
import {SetOnStart, AddInfoBoxMessage} from './Scripts/InfoBoxManager';
import ServerManagement from './Pages/ServerManagement/ServerManagement';
import ServerLogs from './Pages/ServerLogs/ServerLogs';

const cookies = new Cookies();

const App = () => {
  const navigate = useNavigate();

  //Type: 0 - Error, 1 - Success
  const [infoBoxTextData, setInfoBoxTextData] = useState([]);
  const [refresh, setRefresh] = useState("");

  SetOnStart(setInfoBoxTextData, setRefresh);

  let UserPermissions = 0;

  if(!cookies.get("jwt")) {
    RefreshToken(true, navigate, true);
  }
  else 
  {
    try {
      UserPermissions = jwtDecode(cookies.get("jwt")).Permissions;
    } catch {
      cookies.remove("jwt");
    } 
  }

  useEffect(() => {}, [refresh])

  useEffect(() => 
  {
    clearInterval(() => RefreshToken(true, navigate, false));
    RefreshToken(true, navigate, false);
      
    setInterval(() => {
        RefreshToken(false, navigate, false);
    }, 30000)
  }, [])

  const SelectServer = (id) => {
    navigate("/remote/"+id)
  }
  const SelectLogs = (id) => {
    navigate("/logs/"+id)
  }

  return (
    <div className="App">
      <div className="container">
        <div className="panel">
          <div className="title_bar">
            <div className="title_bar_icon"><FaUserFriends size="3vh" /></div>
            <div className="title_bar_text">Web Panel</div>
            
            { window.location.pathname == "/servers" &&
            <div style={{marginLeft:"auto"}}>
              <FaUser size="2.5vh" className='iconButton' onClick={()=> {navigate("/account")}}/>
              { CheckPermission(null, UserPermissions, Permissions.Admin, true) &&
                <IoMdSettings size="2.5vh" style={{marginLeft:"5px"}} className='iconButton' onClick={()=> {navigate("/settings")}}/>
              }
            </div>
            }

            { (window.location.pathname != "/servers" && window.location.pathname != "/") &&
              <IoMdHome size="3vh" style={{marginLeft:"auto"}} className='iconButton' onClick={()=> {navigate("/servers")}}/>
            }

          </div>
          <Routes>
              <Route path='/servers' element={<ServerList selectServer={SelectServer} selectLogs={SelectLogs} />}/>
              <Route path='/' element={<LoginPage />}/>
              <Route path='/logs/:id' element={<ServerLogs/>}/>
              <Route path='/management/:id' element={<ServerManagement/>}/>
              <Route path='/remote/:id' element={ <RemoteAdmin/> }/>
              <Route path='/settings' element={<Settings/>}/>
              <Route path='/account' element={<AccountSettings/>}/>
              <Route path='/servergroup/:id' element={<ServerGroup/>}/>
          </Routes>
        </div>
      </div>

      <div className='infoBoxContainer'>
        { infoBoxTextData.map((info, index) => 
          <InfoBox key={"infoBox_" + index} text={info.text} index={index} type={info.type}/>
        )}
      </div>
    </div>
  );
}

export default App;
