import { useEffect, useState } from "react";
import { AddUserToGroup, GetAllGroupUsers } from "../../../Scripts/Requests/ServerGroupUsersRequests";
import ModalWindow from "../../Elements/ModalWindow/ModalWindow";
import { FaPlus } from "react-icons/fa";
import ServerGroupUserPanel from "../Elements/ServerGroupUserPanel";
import { GetUsers } from "../../../Scripts/Requests/UsersRequests";
import { useNavigate } from "react-router-dom";
import UserAddPanel from "../Elements/UserAddPanel";
import "./ServerGroupUserList.css"
import { SCPPermissions, SCPPermissionsNames } from "../../../Data/SCP/SCPPermissions";

const ServerGroupUserList = ({groupId}) => {

    const navigate = useNavigate();

    const [refresh, setRefresh] = useState("");

    const [addUserWindowStep, setAddUserWindowStep] = useState(0)
    const [userWindow, setAddUserWindow] = useState(false);

    const [usersToGroup, setUsersToGroup] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    
    const [users, setUsers] = useState([]);

    const ChangeUsersSelection = (userId) => {
        let selected = selectedUsers;

        if(selected.find(x => x == userId)) {
            selected.splice(selected.indexOf(userId), 1);
            setRefresh("userRemove_" + userId);
        }
        else {
            selected.push(userId);
            setRefresh("userAdd_" + userId);
        }
        
        setSelectedUsers(selected);
    } 

    const AddUsers = () => {
        selectedUsers.map(userId =>
            AddUserToGroup(groupId, userId, userPermissions, () => {
                GetAllGroupUsers(groupId, setUsersToGroup);
                setAddUserWindow(false); 
                setAddUserWindowStep(0);
                setSelectedUsers([]);
            })
        );
    }

    const [userPermissionsArray, setUserPermissionsArray] = useState([]);
    const [userPermissions, setUserPermissions] = useState(0);

    function OnPermissionCheckedChange(isChecked, enumNumber) {
        let arrayToEdit = userPermissionsArray;
        
        if(isChecked)
            arrayToEdit.push(SCPPermissions[enumNumber]);
        else
            arrayToEdit.splice(arrayToEdit.indexOf(SCPPermissions[enumNumber]), 1);

        setUserPermissionsArray(arrayToEdit);

        let permissionNumber = 0;
        userPermissionsArray.forEach((permission) => permissionNumber += permission);

        setUserPermissions(permissionNumber)
    }

    useEffect(() => {
        GetUsers(setUsers);
        GetAllGroupUsers(groupId, setUsersToGroup);
    }, []);

    const usersToAdd = users.filter(user => !usersToGroup.find(userToGroup => userToGroup.userId == user.panelUser.id));
    return (
        <>
            <div className="widgetListContainer" style={{maxHeight: "80%"}}>
                { usersToGroup.map((userToGroup) => 
                    <ServerGroupUserPanel SetUsers={setUsersToGroup} userToGroup={userToGroup} />
                )}
                <button onClick={() => setAddUserWindow(true)} className="widgetListMainButton">Add User <FaPlus style={{marginLeft: "0.25vw"}}/></button>
            </div>      
            

            {  userWindow &&
                <ModalWindow showCloseButton={true} closeFunction={() => setAddUserWindow(false)} title={"Add users"} width={"60%"} content={
                    <>
                        { addUserWindowStep == 0 &&
                            <>
                                { usersToAdd.length != 0 &&
                                    <>{ usersToAdd.map((user) => 
                                        <UserAddPanel user={user} ChangeUsersSelection={ChangeUsersSelection} selectedUsers={selectedUsers}/>
                                    )}
                                        <button onClick={() => setAddUserWindowStep(1)} className="widgetListMainButton">Next Step</button>
                                    </>
                                }
    
                                { usersToAdd.length == 0 &&
                                    <p>There are no users to add!</p>
                                }
                            </>
                        }
                        { addUserWindowStep == 1 &&
                            <>
                                <div style={{width: "100%", marginBottom: "20px"}}>
                                    <p>Permissions</p>
                                    {
                                        SCPPermissionsNames.map(permissionName => 
                                            { 
                                                if(permissionName.Enum != "Root")
                                                return(
                                                    <div style={{display: "inline-block", width:"25%"}}>
                                                        <input onChange={e => OnPermissionCheckedChange(e.target.checked, permissionName.Enum )} type="checkbox" />
                                                        <label>{permissionName.Name}</label>
                                                    </div>
                                                )
                                            }                              
                                        )
                                    }
                                </div>                                     
                                <div style={{display:"flex"}}>                           
                                    <button style={{width:"50%"}} onClick={() => setAddUserWindowStep(0)}>Previous Step</button>
                                    <button style={{width:"50%"}} onClick={() => AddUsers()}>Add</button>
                                </div>

                            </>
                        }

                    </>
                }/>
            }  

        </>
    );
}

export default ServerGroupUserList;
