import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests.js";
import GroupButtons from "../../../Elements/GroupButtons/GroupButtons";
import "./Moderation.css";
import { FaVolumeDown, FaVolumeMute } from "react-icons/fa";
import PredefinedReasonData from "../../../../Data/PredefinedReasonsData.js";
import Bans from "../../../../Data/Bans";
import PredefinedReason from "./Elements/PredefinedReason.js";
import { AddInfoBoxMessage } from "../../../../Scripts/InfoBoxManager.js";
import { BanPlayersWhoLeft } from "../../../../Scripts/Requests/PlayersRequests.js";

const Moderation = ({GetPlayersGameIds, GetPlayersWhoLeft, RefreshPlayers}) =>
{
    const { id } = useParams();
    const navigate = useNavigate;

    const [reason, setReason] = useState("")
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedButtonNumber, setSelectedButtonNumber] = useState(0);

    const BanUser = () => {

        if(reason.length < 1)
        {
            AddInfoBoxMessage("You need to provide a reason for the ban", 0);
            return;
        }         
        
        ExecuteBanUser(Bans[selectedButtonNumber].Time, reason)
    }

    const ExecuteBanUser = (time, banReason) => 
    {
        const playerIds = GetPlayersGameIds();
        const playersWhoLeft = GetPlayersWhoLeft();

        if(playerIds.length > 1 || playersWhoLeft.length > 0)
        {
            const command = "/ban " + playerIds + " " + time + " " + banReason;
            ExecuteCommand(id, command);
            BanPlayersWhoLeft(id, playersWhoLeft, banReason, time, RefreshPlayers);
        }
        else
        {
            AddInfoBoxMessage("You need to select user(s) for this action", 0);
        }
    }

    const ExecuteCommandWithPlayer = (commandToExecute) => {
        const playerIds = GetPlayersGameIds();

        if(playerIds.length > 1)
        {
            const command = commandToExecute + playerIds;
            ExecuteCommand(id, command, navigate);
        }else
        {
            AddInfoBoxMessage("You need to select user(s) for this action", 0);
        }
    }

    return(
    <div className="optionsPanelContainer">
        <div className="optionsPaneldescription" style={{textAlign:"center"}}>Select player(s) from list, then choose ban duration and confirm your decision</div>
        <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "PREDEFINED REASONS"}, {text: "CUSTOM REASON"}, {text: "MUTE MANAGER"}]}/>
        {/* PREDEFINED REASONS BANS */}
        { selectedCategory == 0 &&
            <div className="moderationContainer">
                <div className="predefinedReasonsContainer">
                    { PredefinedReasonData.map((reasonData, index) => 
                        <PredefinedReason key={`PredefinedReason_${index}`} reason={reasonData.Reason} time={reasonData.Time} ExecuteBanUser={ExecuteBanUser} GetPlayersGameIds={GetPlayersGameIds} />
                    )}
                </div>
            </div>
        }
        {/* CUSTOM REASON BANS */}
        { selectedCategory == 1 &&
            <div className="moderationContainer">
                <div className="punishmentTimeContainer">
                    { Bans.map((ban, index) => 
                        <button onClick={() => setSelectedButtonNumber(index)} key={"banButton_" + index} className={"squareButton" + (selectedButtonNumber == index ? " selectedBanButton" : "")}>{ban.Text}</button>
                    )}
                </div>
                <div className="reasonTextBoxContainer">
                    <input onChange={e => setReason(e.target.value)} type="text" className="reasonTextBox" placeholder="Specify a reason behind the sanction." style={{borderColor:"transparent"}}></input>      
                </div>
                <button onClick={() => BanUser()} style={{width:"25%", height:"5vh"}} className="customReasonRequestBtn">Request</button>  
            </div>
        }
        {/* MUTE MANAGER */}
        { selectedCategory == 2 &&
            <div className="moderationContainer">
                <div className="muteActionsContainer">
                    <div className="muteButtonContainer">
                        <button onClick={() => ExecuteCommandWithPlayer("/mute ")} className="squareButton"><FaVolumeMute size="2.5vw" color="#ff383b"/></button>
                        Voicechat Mute
                    </div>
                    <div className="muteButtonContainer">
                        <button onClick={() => ExecuteCommandWithPlayer("/unmute ")} className="squareButton"><FaVolumeDown size="2.5vw" color="#b977f2"/></button>
                        Voicechat Unmute
                    </div>
                    <div className="muteButtonContainer">
                        <button onClick={() => ExecuteCommandWithPlayer("/imute ")} className="squareButton"><FaVolumeMute size="2.5vw" color="#ff383b"/></button>
                        Intercom Mute
                    </div>
                    <div className="muteButtonContainer">
                    <button onClick={() => ExecuteCommandWithPlayer("/iunmute ")} className="squareButton"><FaVolumeDown size="2.5vw" color="#43d15f"/></button>
                        Intercom Unmute
                    </div>
                </div>
                {/* <button style={{width:"25%", height:"5vh"}} className="customReasonRequestBtn">Request</button>      */}
            </div>
        }
    </div>);
}

export default Moderation;