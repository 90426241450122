import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("PanelLogs/");

const GetPanelLogs = (setLogs) => {

    defaultAxios.get('Get')
    .then((res) => 
    {
        setLogs(res.data);
    });
}

export { GetPanelLogs }