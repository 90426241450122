import { AddInfoBoxMessage } from "../InfoBoxManager";
import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("PanelUsers/");


const GetUser = (userId, SetUser) => 
{
    defaultAxios.get("Get/" + userId)
    .then((res) => 
    {
        SetUser(res.data);
    });
}

const GetUsers = (SetUsers) => 
{
    defaultAxios.get("GetAll")
    .then((res) => 
    {
        SetUsers(res.data);
    });
}
const AddUser = (UserData, SetUsers) => {
    defaultAxios.post("AddNewUser", UserData)
    .then((res) => 
    {
        AddInfoBoxMessage("User added successfully.", 1);
        GetUsers(SetUsers);
    });
}
const EditUser = (UserData, SetUsers) => {
    defaultAxios.post("EditUser", UserData)
    .then((res) => 
    {
        AddInfoBoxMessage("User edited successfully.", 1);
        GetUsers(SetUsers);
    });
}
const RemoveUser = (Id, SetUsers) => {
    defaultAxios.delete("RemoveUser/" + Id)
    .then(() => 
    {
        AddInfoBoxMessage("User removed successfully.", 1);
        GetUsers(SetUsers);
    });
}

const GetSteamData = (Id, SetData) => {
    defaultAxios.get("GetUserSteamData/"+Id)
    .then((res) => 
    {
        SetData(res.data.response.players)
    });
}

const ResetPassword = (Id) => {
    defaultAxios.post("ResetPassword/" + Id, null)
    .then((res) => 
    {
        AddInfoBoxMessage("The password has been reset successfully. The new password is in the user's email.", 1);
    });
}

export { GetUsers, AddUser, EditUser, RemoveUser, ResetPassword, GetSteamData, GetUser }