import { AddInfoBoxMessage } from "../InfoBoxManager";
import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("ServerUsersPanel/");

const GetAllUsers = (serverId, SetPlayers) => 
{
  defaultAxios.get("GetAll/" + serverId)
  .then((res) => 
  {
    SetPlayers(res.data);
  });
}

const GetPlayerIP = (serverId, playerId, SetPlayerIP) => 
{
  defaultAxios.get("GetPlayerIP/"+serverId + "/" + playerId)
  .then((res) => 
  {
    SetPlayerIP(res.data);
  });
}

const BanPlayersWhoLeft = (serverId, players, reason, Time, RefreshPlayers) => 
{
  if(players.length < 1)
    return;
  
  const data = {
    PlayersToBanList: players,
    ServerId: serverId,
    Reason: reason,
    Time: Time
  }

  defaultAxios.post("BanOfflinePlayers", data, { headers: { "Content-Type": "application/json" }})
  .then((res) => 
  { 
    AddInfoBoxMessage("Player(s) who left the server have been successfully banned!", 1);
    RefreshPlayers();
  });
}

export { GetAllUsers, GetPlayerIP, BanPlayersWhoLeft };