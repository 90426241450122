import { useState } from "react";
import { EditPunishment, RemovePunishment } from "../../../../Scripts/Requests/ServerRequests";
import ModalWindow from "../../../Elements/ModalWindow/ModalWindow";
import Bans from "../../../../Data/Bans";
import { AddInfoBoxMessage } from "../../../../Scripts/InfoBoxManager";
import { GetDateFromTicks, GetTicksFromDate } from "../../../../Scripts/DateManager";

const SCPBan = ({serverId, ban, setPunishments, ip, setReload} ) => {
    const banData = ban.split(';');
    const type = ip ? 1 : 0;

    const [showEditBanModal, setShowEditBanModal] = useState(false);
    const [selectedButtonNumber, setSelectedButtonNumber] = useState(0);
    const [banReason, setBanReason] = useState(banData[3]);

    if(!ban)
        return (<></>);

    const Unban = () => {
        
        RemovePunishment(serverId, type, ban, setPunishments, setReload)
        setReload("unban-"+type+"-" + ban);
    }
    const EditBan = () => {
        if(selectedButtonNumber == 0) {
            AddInfoBoxMessage("You must select ban time!", 0)
            return;
        }

        if(banReason.length < 1) {
            AddInfoBoxMessage("You must type ban reason!", 0)
            return;
        }

        const banDate = GetDateFromTicks(banData[5]);
        const newBanEndDate = new Date(banDate.getTime() + parseInt(Bans[selectedButtonNumber].Time) * 1000 * 60)

        const editedBan = `${banData[0]};${banData[1]};${GetTicksFromDate(newBanEndDate)};${banReason};${banData[4]};${banData[5]}`; 

        EditPunishment(serverId, type, ban, editedBan, setPunishments, setReload);
        setShowEditBanModal(false); 
    }


    return (
        <div className="listWidget" style={{padding:"7px", marginBottom:"10px"}}>
            <div style={{margin:"5px", display:"flex"}}>
                <div style={{width:"20%"}}>
                    <h2>User</h2>
                    <p>Nick: {banData[0]}</p>
                    <p>Id: {banData[1]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Banned by</h2>
                    <p>{banData[4]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Reason</h2>
                    <p>{banData[3]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Time</h2>
                    <p>Banned: {GetDateFromTicks(banData[5]).toLocaleDateString()}</p>
                    <p>Banned until: {GetDateFromTicks(banData[2]).toLocaleDateString()}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Actions</h2>
                    <button onClick={() => Unban()} style={{width:"90%"}}>Unban</button>
                    <button onClick={() => {setShowEditBanModal(true);  setSelectedButtonNumber(0); setBanReason(banData[3])}} style={{width:"90%"}}>Edit</button>
                </div>
                
                { showEditBanModal &&
                    <ModalWindow width={"fit-content"} closeFunction={() => setShowEditBanModal(false)} showCloseButton={true} title={"Edit Ban"} content={
                        <>
                            <p>Reason</p>
                            <input onChange={(e) => setBanReason(e.target.value)} value={banReason} style={{margin:"0px 5px 30px", width:"calc(100% - 10px)"}} type="text" className="inputStyle"></input>
                            
                            <div style={{margin:"0px 5px", width:"calc(100% - 10px)"}} className="punishmentTimeContainer">
           
                                { Bans.map((ban, index) => 
                                    { if(ban.Time != 0) {
                                            return (<button onClick={() => setSelectedButtonNumber(index)} key={"banButton_" + index} className={"squareButton" + (selectedButtonNumber == index ? " selectedBanButton" : "")}>{ban.Text}</button>)
                                        } 
                                    }
                                )}
                            </div>

                            <button onClick={() => EditBan()} style={{margin:"50px 5px 5px", width:"calc(100% - 10px)"}}>Edit</button>
                        </>
                    }/> 
                }
            </div>
        </div>
    );
};

export default SCPBan;