import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("ServerGroups/");


const GetAllServerGroups = (SetServerGroups, navigate) => 
{
    defaultAxios.get("GetAll")
    .then((res) => 
    {
        SetServerGroups(res.data);
    });
}

const GetServerGroup = (SetServerGroup, Id) => 
{
    defaultAxios.get("Get/" + Id)
    .then((res) => 
    {
        SetServerGroup(res.data);
    });
}

const AddServerGroup = (SetServerGroups, navigate, groupName, serverType, setGroupWindow) => 
{
    const data = {
        Name: groupName,
        ServerType: serverType
    }

    defaultAxios.post("Add", data)
    .then((res) => 
    {
        GetAllServerGroups(SetServerGroups, navigate);
        setGroupWindow(false);
    });
};

const RemoveServerGroup = (id, SetServerGroups, navigate) => 
{
    defaultAxios.delete("Remove/" + id)
    .then((res) => 
    {
        GetAllServerGroups(SetServerGroups, navigate);
    });
};

export { GetAllServerGroups, GetServerGroup, AddServerGroup, RemoveServerGroup };
