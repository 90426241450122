import {PermissionsNames, Permissions} from "../../../Data/Permissions.js";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";
import { useEffect, useState } from "react";
import { GetSteamData } from "../../../Scripts/Requests/UsersRequests.js";
import { FaSteam, FaCopy, FaPowerOff } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../Scripts/Requests/AuthRequests.js";
import { GetPermissionsToDisplay } from "../../../Scripts/PermissionsManager.js";

const AccountData = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    
    let userData = {};

    try {
        userData = jwtDecode(cookies.get("jwt"));
    } catch{
        navigate("/");
    }

    const [steamData, SetSteamData] = useState([]);

    useEffect(() => { 
        GetSteamData(userData.Id, SetSteamData)
    }, []);

    const OpenSteam = () => {
        window.open("https://steamcommunity.com/profiles/" + userData.SteamId64, "_blank");
    }

    const LogoutClick = () => {
        try {
            Logout(userData.RefreshTokenId, () => {
                cookies.remove("jwt");
                cookies.remove("refreshToken");
                navigate("/");
            });
        } catch 
        {
            cookies.remove("jwt");
            cookies.remove("refreshToken");
            navigate("/");
        }
    }
    
    return (
        <>
            <div className="steamProfileImageContainer">
                <div>
                    { steamData[0] != null &&
                        <img  className="steamProfileImage" style={{objectFit:"scale-down"}} alt="steam profile icon" src={steamData[0].avatarfull}></img>
                    }
                </div>
            </div>

            <div className="profileDataTableContainer tableStyles">
                <table>
                    <tr>
                        <th className="infoCell">Login:</th>
                        <th className="dataCell">{ userData.Login }</th>
                    </tr>
                    <tr>
                        <th className="infoCell">Account Created:</th>
                        <th className="dataCell">{ userData.CreationData }</th>
                    </tr>
                    <tr>
                        <th className="infoCell">Email:</th>
                        <th className="dataCell">{ userData.Email }</th>
                    </tr>
                    <tr>
                        <th className="infoCell">Steam ID:</th>
                        <th className="dataCell">
                            { userData.SteamId64 }
                            <FaCopy onClick={() => {navigator.clipboard.writeText(userData.SteamId64)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"1.5vh"}/>
                        </th>
                    </tr>
                    <tr>
                        <th className="infoCell">Discord ID</th>
                        <th className="dataCell">
                            { userData.DiscordId }
                            <FaCopy onClick={() => {navigator.clipboard.writeText(userData.DiscordId)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"1.5vh"}/>
                        </th>
                    </tr>
                </table> 
            </div>

            <div className="accountInfoTitle">Permissions:</div>
            
            <div className="accountListContainer">
                <GetPermissionsToDisplay permissions={userData.Permissions} permissionsNames={PermissionsNames} permissionsEnum={Permissions}/>
            </div>

            <div className="buttonContainer">
                <button style={{width:"25%"}} onClick={() => OpenSteam()}><FaSteam style={{marginRight: "0.4vw"}}/>Steam</button>
                <button style={{width:"25%"}} onClick={() => LogoutClick()}><FaPowerOff style={{marginRight: "0.4vw"}}/>Logout</button>    
            </div>
        </>
    );
}

export default AccountData;